import { useState, useEffect } from 'react';
import hounddogLogoBlack from '/logo-b.png';
import { cn } from '~/lib/utils';

interface LoadingAnimationProps {
  size?: 'small' | 'medium' | 'large';
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  size = 'medium',
}) => {
  const dots = ['.', '..', '...'];
  const [dotsIndex, setDotsIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotsIndex((prevIndex) => (prevIndex + 1) % dots.length);
    }, 400);

    return () => clearInterval(interval);
  }, [dots.length]);

  return (
    <div className='flex flex-col items-center'>
      <img
        src={hounddogLogoBlack}
        alt='loading houndog logo'
        className={cn({
          'max-w-8': size === 'small',
          'max-w-14': size === 'medium',
          'max-w-24': size === 'large',
        })}
      />
      <h2
        className={cn('font-medium', {
          'mt-1 w-[5.25rem] text-base': size === 'small',
          'mt-2 w-[6.5rem] text-xl': size === 'medium',
          'mt-4 w-40 text-3xl': size === 'large',
        })}
      >
        Loading {dots[dotsIndex]}
      </h2>
    </div>
  );
};

export default LoadingAnimation;
